import React, { useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { Element } from "react-scroll";
import previewVideo from "../../assets/img/1-introduction-to-the-course-free.mp4";
import fullCoursePreview from "../../assets/img/full-course-intro.mp4";

const MainWrap = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const Title = styled.h2`
  padding-top: 1rem;
  padding-bottom: 2rem;
`;

const CtaButtons = styled.div`
  width: 100%;
  margin: 0.5rem;
  a {
    width: 100%;
    text-align: "center";
    margin-bottom: 1rem;
  }
`;

const PricePanelSecondary = styled.div`
  display: flex;
  flex-direction: row;
`;

const PricePanelWrap = styled.div`
  padding: 0.25rem;
  margin: 0.25rem;
  border-color: #ddd;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  width: 100%;
`;

const CourseWrap = styled.div`
  padding: 0.5rem;
`;

const VideoModal = styled.div`
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const VideoContainer = styled.div`
  position: relative;
  width: 80%;
  max-width: 800px;
  background: #000;
  padding: 20px;
  border-radius: 8px;

  video {
    width: 100%;
  }

  .close-button {
    position: absolute;
    top: -15px;
    right: -15px;
    background: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
`;

const VideoPreviewModal = ({ isOpen, onClose, videoSrc }) => {
  if (!isOpen) return null;

  return (
    <VideoModal isOpen={isOpen} onClick={onClose}>
      <VideoContainer onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>
          ×
        </button>
        <video controls autoPlay>
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </VideoContainer>
    </VideoModal>
  );
};

const CoursePanel = ({ course }) => {
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

  return (
    <div key={course.courseName} className="column">
      <div className="card">
        <div className="card-image">
          <a href={course.link} target="_blank" rel="noopener noreferrer">
            <img src={course.image} alt={course.courseName} />
          </a>
        </div>
        <div className="card-content">
          <div className="content">
            <h3>{course.courseName}</h3>
            <p>{course.description}</p>
            <p>
              <strong>Type:</strong> {course.type}
              <br />
              <strong>Length:</strong> {course.length}
            </p>
            {course.showPreview && (
              <div style={{ margin: "0.5rem", width: "100%" }}>
                <button
                  className="button is-primary is-fullwidth"
                  onClick={() => setIsVideoModalOpen(true)}
                >
                  Watch Preview Video
                </button>
              </div>
            )}
            {course.cta}
            {course.curriculum && (
              <>
                <h4>Course Curriculum:</h4>
                {course.curriculum}
              </>
            )}
          </div>
        </div>
      </div>
      <VideoPreviewModal
        isOpen={isVideoModalOpen}
        onClose={() => setIsVideoModalOpen(false)}
        videoSrc={course.previewVideo}
      />
    </div>
  );
};

const DetailedCurriculum = styled.div`
  margin-top: 2rem;
  
  .curriculum-section-item {
    margin-bottom: 1.5rem;
  }
  
  .section-title {
    font-weight: bold;
    color: #363636;
    margin-bottom: 0.75rem;
    font-size: 1.2em;
  }
  
  .video-item {
    display: flex;
    justify-content: space-between;
    padding: 0.4rem 0;
    border-bottom: 1px solid #f0f0f0;
    
    .video-title {
      flex: 1;
    }
    
    .duration {
      color: #666;
      font-size: 0.9em;
      margin-left: 1rem;
    }
  }
  
  .resource-item {
    color: #4a4a4a;
    padding: 0.3rem 0;
  }
`;

const FULL_COURSE_CURRICULUM = [
  {
    title: "Section 1: Welcome!",
    items: [
      { name: "Intro to the course!", duration: "6:44" },
      { name: "Technical Terms Resources", type: "resource" }
    ]
  },
  {
    title: "Section 2: Research",
    items: [
      { name: "Survey Results and Research", duration: "13:56" },
      { name: "Blog & Survey links", type: "resource" }
    ]
  },
  {
    title: "Section 3: How engineers feel about work and recruiters",
    items: [
      { name: "Engineer's Opinions", duration: "10:08" },
      { name: "Why programmers leave their jobs", duration: "5:43" },
      { name: "Example Job Titles", type: "resource" },
      { name: "Cold emails (Blog Links and more)", type: "resource" },
      { name: "Subject Lines", type: "resource" },
      { name: "Cold Email Examples", type: "resource" },
      { name: "Email Templates", type: "resource" }
    ]
  },
  {
    title: "Section 4: Software Development Process (the business side)",
    items: [
      { name: "The lifecycle and process of developing software", duration: "13:28" },
      { name: "The Develop / Test / Deploy Process - a more detailed look", duration: "6:07" },
      { name: "A day in the life of a developer", duration: "6:57" },
      { name: "Business of Producing Software - includes job titles and tracks", duration: "13:17" },
      { name: "Types of Work Environments", duration: "9:28" }
    ]
  },
  {
    title: "Section 5: UI Design / Development",
    items: [
      { name: "UI/UX Developers/Designers. What do all these titles mean?", duration: "3:58" },
      { name: "A Walkthrough of Various Design Systems", duration: "7:11" },
      { name: "Design Systems Examples", type: "resource" }
    ]
  },
  {
    title: "Section 6: Frontend Vs Backend",
    items: [
      { name: "Part 1: Intro Video", duration: "0:59" },
      { name: "Part 2: Intro to Client Server Model", duration: "3:11" },
      { name: "Part 3: Anatomy of a Real System (LinkedIn)", duration: "4:06" },
      { name: "Part 4: Intro to Application Infrastructure", duration: "3:44" },
      { name: "Part 5: Frontend Vs Backend Vs Fullstack developers comparison", duration: "4:39" },
      { name: "Part 6: Where to find Frontend developers", duration: "1:04" },
      { name: "Part 7: Key Frontend Technologies", duration: "3:30" },
      { name: "Part 8: Where to Find Backend Developers", duration: "2:05" },
      { name: "Part 9: Key Backend Technologies", duration: "5:08" },
      { name: "PDF Download of slides with tech terms", type: "resource" },
      { name: "Blog Links and Relevant Articles", type: "resource" }
    ]
  },
  {
    title: "Section 7: Intro To HTML, CSS, and JS",
    items: [
      { name: "HTML, CSS and JavaScript - a coding intro", duration: "11:57" },
      { name: "Links from the video", type: "resource" }
    ]
  },
  {
    title: "Section 8: Tech Stacks and Technology Details",
    items: [
      { name: "Tech Stacks Part 1: Introduction", duration: "2:10" },
      { name: "Tech Stacks Part 2: LAMP Stack", duration: "2:14" },
      { name: "Tech Stacks Part 3: MEAN Stack", duration: "2:26" },
      { name: "Tech Stacks Part 4: Ruby On Rails", duration: "2:13" },
      { name: "Tech Stacks Part 5: Django (python)", duration: "1:27" },
      { name: "Tech Stacks Part 6: Spring (Java)", duration: "2:25" },
      { name: "Tech Stacks Part 7: ASP.NET (microsoft)", duration: "1:10" },
      { name: "Tech Stacks Part 8: Serverless systems", duration: "2:04" },
      { name: "Tech Stacks Part 9: JAM Stack", duration: "1:54" },
      { name: "Tech Stacks Part 10: Summary and takeaways", duration: "1:53" },
      { name: ".NET vs Java", duration: "10:53" },
      { name: "Client Side Frameworks: SPAs vs Template Rendering", duration: "9:39" },
      { name: "What About Wordpress?", duration: "4:58" },
      { name: "Desktop Application Development", duration: "3:25" }
    ]
  },
  {
    title: "Section 9: Databases",
    items: [
      { name: "Part 1: Introduction to Databases", duration: "1:35" },
      { name: "Part 2: Intro To Relational Databases (SQL Databases)", duration: "5:08" },
      { name: "Part 3: Types of Relational Databases", duration: "1:41" },
      { name: "Part 4: Intro to NoSQL Databases", duration: "0:47" },
      { name: "Part 5: Types of NoSQL Databases", duration: "5:21" },
      { name: "Part 6: Database Hosting and Management Systems", duration: "2:07" },
      { name: "Part 7: People & Trends in Databases", duration: "4:54" },
      { name: "PDF download of slides with tech terms", type: "resource" }
    ]
  },
  {
    title: "Section 10: Mobile Devs Vs Web Devs",
    items: [
      { name: "Mobile Devs Vs Web Devs part 1", duration: "11:52" },
      { name: "Mobile Devs Vs Web Devs Part 2", duration: "2:38" }
    ]
  },
  {
    title: "Section 11: Open Source and Github",
    items: [
      { name: "Open Source and Github", duration: "8:19" },
      { name: "Version Control and Package Managers", duration: "6:28" },
      { name: "Github Live Walkthrough", duration: "7:01" },
      { name: "Links and Additional Info", type: "resource" }
    ]
  },
  {
    title: "Section 12: Common Points of Confusion",
    items: [
      { name: "Intro", duration: "0:25" },
      { name: "Git Vs Github", duration: "3:26" },
      { name: "Java Vs JavaScript", duration: "2:01" },
      { name: "React Vs Angular", duration: "2:36" },
      { name: "Graph Databases Vs GraphQL", duration: "2:52" }
    ]
  },
  {
    title: "Section 13: Advanced Software Engineering Concepts",
    items: [
      { name: "Paradigms and Design Patterns", duration: "10:07" },
      { name: "Message Queues and Kafka", duration: "7:59" },
      { name: "Docker - what is it, why are people using it?", duration: "12:52" }
    ]
  },
  {
    title: "Section 14: Infrastructure & DevOps",
    items: [
      { name: "Part 1 - Introduction video", duration: "1:11" },
      { name: "Part 2 - Client Server Model", duration: "1:46" },
      { name: "Part 3 - Application Infrastructure", duration: "2:50" },
      { name: "Part 4 - Server Infrastructure", duration: "5:03" },
      { name: "Part 5 - DevOps", duration: "6:10" },
      { name: "When you go to a website what happens - video", duration: "3:42" },
      { name: "When you go to a website what happens - links", type: "resource" }
    ]
  },
  {
    title: "Section 15: Evaluation",
    items: [
      { name: "Part 1: Intro to evaluating software engineers", duration: "6:51" },
      { name: "Part 2: Experience based questions", duration: "2:57" },
      { name: "Part 3: Engineering questions and answers", duration: "4:20" },
      { name: "Part 4: Advice from \"Technology Made Simple\"", duration: "2:04" },
      { name: "Part 5: React specific evaluation questions", duration: "2:29" },
      { name: "Evaluation Slides (with questions)", type: "resource" }
    ]
  },
  {
    title: "Closing Statement",
    items: [
      { name: "Closing video", duration: "1:21" }
    ]
  }
];

const DetailedCurriculumComponent = ({ curriculum = FULL_COURSE_CURRICULUM }) => (
  <DetailedCurriculum>
    {curriculum.map((section, i) => (
      <div key={i} className="curriculum-section-item">
        <div className="section-title">{section.title}</div>
        {section.items.map((item, j) => (
          item.type === 'resource' ? (
            <div key={j} className="resource-item">
              📄 {item.name}
            </div>
          ) : (
            <div key={j} className="video-item">
              <span className="video-title">🎥 {item.name}</span>
              <span className="duration">{item.duration}</span>
            </div>
          )
        ))}
      </div>
    ))}
  </DetailedCurriculum>
);

const FREE_COURSE_CURRICULUM = [
  {
    title: "Introduction",
    items: [
      { name: "Introduction to the course", duration: "1:17" }
    ]
  },
  {
    title: "Part 1: Process",
    items: [
      { name: "Introduction to Process", duration: "2:38" },
      { name: "Code to Deploy Process", duration: "2:56" }
    ]
  },
  {
    title: "Part 2: People",
    items: [
      { name: "People on a Scrum team & Iterative development", duration: "4:12" }
    ]
  },
  {
    title: "Part 3: Infrastructure",
    items: [
      { name: "Client Server Model", duration: "1:06" },
      { name: "Web App Technology", duration: "2:23" },
      { name: "Tech stack examples", duration: "3:13" },
      { name: "Java Microservices Example", duration: "3:11" },
      { name: "Realistic Cloud Infrastructure", duration: "2:17" }
    ]
  },
  {
    title: "Closing",
    items: [
      { name: "Thank You!", duration: "0:59" }
    ]
  }
];

const COURSES_LIST = [
  {
    image: "/img/parts-of-a-software-project-thumb-1.jpg",
    link: "https://iteach-recruiters-public.s3.us-west-2.amazonaws.com/iteach-recruiters-free-demo-course.zip",
    courseName: "Parts of a Software Project (FREE)",
    showPreview: true,
    previewVideo: previewVideo,
    description: (
      <div>
        <p>
          A mini-course overview of the process, people, and infrastructure in a
          typical software project.
        </p>
        <p>
          Not sure if you want to get the full course? Try this one out first.
        </p>
      </div>
    ),
    type: "Downloadable Video Course, ~20 minutes",
    length: "About 20 minutes",
    plan: "Single Seat",
    price: "Free",
    items: [],
    cta: (
      <CtaButtons>
        <a
          href="https://iteach-recruiters-public.s3.us-west-2.amazonaws.com/iteach-recruiters-free-demo-course.zip"
          className="button is-info"
          target="_blank"
          rel="noopener noreferrer"
        >
          Download Free Course NOW!
        </a>
        <div style={{ marginTop: '2rem' }}>
          <h4 className="is-size-4">Course Curriculum:</h4>
          <DetailedCurriculumComponent curriculum={FREE_COURSE_CURRICULUM} />
        </div>
      </CtaButtons>
    ),
  },
  {
    image: "/img/how-to-speak-jargon-thumbs-1.jpg",
    courseName: "How To Speak Software Engineering Jargon For Recruiters (ONLY $199)",
    showPreview: true,
    previewVideo: fullCoursePreview,
    length: "About 5.5 hours",
    description: (
      <div>
        <p>
          The flagship Course! Learn the basics of building software and common
          web development technical topics.
        </p>
      </div>
    ),
    type: "Downloadable Video Course, Over 5.5 hours of content!",
    items: [],
    plan: "Single Seat",
    price: "$199.00",
    link: "https://buy.stripe.com/28ocQP7gA8LA39C7st",
    cta: (
      <CtaButtons
        onClick={() => {
          try {
            console.log("track conversion");
            window.gtag("event", "conversion", {
              send_to: "AW-863279616/kBGkCOzLwYsYEIC00psD",
              transaction_id: "",
            });
          } catch (e) {
            console.log("error tracking conversion", e);
          }
        }}
      >
        <div>
          <strong>Note:</strong> After payment, you'll receive an email with the download link. 
          You get lifetime access to all course videos and materials. I just ask that you don't 
          share or resell the content.
          <hr />
        </div>
        <a
          className="button is-primary"
          href="https://buy.stripe.com/28ocQP7gA8LA39C7st"
          target="_blank"
          rel="noopener noreferrer"
        >
          Buy Now - $199
        </a>
        <a
          href="https://tidycal.com/aaron.b.decker/iteach-recruiters-15-minute-discovery-call"
          className="button is-info"
          target="_blank"
          rel="noopener noreferrer"
        >
          Book a call for Bulk Pricing
        </a>
        <div style={{ marginTop: '2rem' }}>
          <h4 className="is-size-4">Full Course Curriculum:</h4>
          <DetailedCurriculumComponent />
        </div>
      </CtaButtons>
    ),
  },
];

const CourseOfferings = () => (
  <Element name="courseofferings">
    <MainWrap>
      <Title className="has-text-centered has-text-primary is-size-2">
        Courses You Will Love
      </Title>
      <div className="columns">
        {COURSES_LIST.map((course, i) => {
          return <CoursePanel key={i} course={course} />;
        })}
      </div>
    </MainWrap>
  </Element>
);

export default CourseOfferings;
