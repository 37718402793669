import React from "react";

const REVIEWS = [
  {
    name: "Grace Fjellanger",
    content: `Aarons course did wonders for me as a new tech recruiter! 
    I started it knowing almost nothing about the tech world and after completing the course, I was able to better articulate how development and design work in order to understand what my candidates do everyday. 
    It's great to be able to speak my candidates language and relate to them on a deeper level. `
  },
  {
    name: "Stephanie Paulson",
    content: `As someone who has never written a line of code before I think this course is something special. 
  Being able to gain deeper technical knowledge as well as insight into how developers truly view the work recruiters do is highly beneficial. 
  This course has the ability to change a lot in the world of technical recruiting.`
  },
  {
    name: "Quinn Marzolf",
    content: `This course is a great way for a new technical recruiter to gain perspective from an engineer's point of view as well as dive deeper into some more technical concepts to more easily relate with engineers. 
  There’s a lot of value to be had and it can definitely help you get up to speed faster as a new recruiter.`
  },
  {
    name: "Chris Kurker",
    content: `A comprehensive look at what software engineers do, the technologies involved, and what their job search is like. 
  Your course is the info I expected to get out of the training for my current job, but unfortunately my training wasn't nearly as thorough.`
  },
  {
    name: "Rick DesLauriers, Owner of Recruiters of Minnesota",
    content:
      "Aaron made a professional and thoughtful presentation to our group of recruiters.  He has a passion for the process that occurs between an technical candidate and a Talent Acquisition Professional and can offer helpful insight into improving that relationship."
  }
];

export const Reviews = ({ title }) => {
  const defaultTitle = "What are people saying about the course?";
  return (
    <div className="section">
      <h2
        style={{ marginBottom: "2rem" }}
        className="has-text-primary is-size-2 has-text-centered has-margin-title"
      >
        {title || defaultTitle}
      </h2>
      <div className="columns" style={{ flexWrap: "wrap" }}>
        {REVIEWS.map((review, i) => {
          return (
            <div className="column is-4" key={i}>
              <div className="card">
                <div className="card-content">
                  <p className="is-italic has-text-weight-light">
                    "{review.content}"
                  </p>
                  <p className="has-text-weight-semibold">- {review.name}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Reviews;
