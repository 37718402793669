import React from 'react'
import Content from '../Content'
import PropTypes from 'prop-types'
import { SubscribeForm } from '../common/subscribeForm'
import styled from 'styled-components'
import CourseOfferingsPanel from '../common/courseOfferingsPanel'
import { Reviews } from '../common/whatHavePeopleSaid'

const CourseOutline = styled.div`
  ul li {
    margin: 1rem;
  }
`

const Course3Component = () => {
  return (
    <div className='section'>
      <h2 className='title'>2. Big Data And Machine Learning Jargon Exaplained Recruiting</h2>
      <div />
      <article className='message'>
        <div className='message-header'>
          <p className='has-text-white'>
            Big data and machine learning concepts at a very high level.
          </p>
        </div>
        <div className='message-body'>
          <p>
            Target Release Data:{' '}
            <span className='tag is-primary is-medium'>March 2020</span>
          </p>
          This course is going to focus on the jargon you need to understand to recruit
          machine learning (aka AI) developers, as well as big data engineers.
        </div>
      </article>
    </div>
  )
}

const Course2Component = () => {
  return (
    <div className='section'>
      <h2 className='title'>3. Advanced JavaScript and Frontend Recruiting</h2>
      <div />
      <article className='message'>
        <div className='message-header'>
          <p className='has-text-white'>
            How to evaluate and recruit frontend JavaScript devs
          </p>
        </div>
        <div className='message-body'>
          <p>
            Target Release Data:{' '}
            <span className='tag is-primary is-medium'>June 2020</span>
          </p>
          This course will focus on recruiting for React, Angular and Vue. This
          will be a little bit different: I will actually go through a lot of
          evaluation tools and show how to gauge skill, experience level and fit
          on teams.
        </div>
      </article>
    </div>
  )
}

const Course1Component = () => {
  return (
    <div className='section'>
      <h2 className='title'>
        1. How To Speak Software Engineering Jargon For Recruiters
      </h2>
      <div />
      <article className='message'>
        <div className='message-header'>
          <p className='has-text-white'>
            The flagship ITeach Recruiters Course!
          </p>
        </div>
        <div className='message-body'>
          <p>
            Target Release Data:{' '}
            <span className='tag is-primary is-medium'>
              Late Nov 2019/ Early December 2019
            </span>
          </p>
          <p>
            This is the first course I am building as part of ITeach Recruiters
            and it will be the flagship course. I plan to cover everything I can
            (broadly) about software engineering, what engineers do, the basic
            lifecycle of producing software and as much Jargon as I can that you
            will see when reading job descriptions and resumes.
          </p>
        </div>
      </article>

      <CourseOutline>
        <h3 className='is-size-5 has-text-weight-semibold'>Course Outline</h3>
        {COURSE_1.map((entry, i) => {
          return (
            <ul>
              <li>
                <h3 className='is-size-5 has-text-weight-medium'>
                  {i + 1}. {entry.name}
                </h3>
                <p>{entry.info}</p>
              </li>
            </ul>
          )
        })}
      </CourseOutline>
    </div>
  )
}

const CoursesPageTemplate = ({
  title,
  description,
  content,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content

  return (
    <div>
      <section className='hero is-primary is-bold'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns'>
              <div className='column is-10 is-offset-1'>
                <div className='section'>
                  <h1 className='title has-text-white is-size-1'>{title}</h1>
                  <h2 className='description has-text-white is-size-3'>
                    {description}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='section section--gradient'>
        <div className='container'>
          <div className='columns'>
            <div className='column is-10 is-offset-1'>
              <CourseOfferingsPanel />

              <hr />

              <Reviews title='Reviews of: How To Speak Software Engineering Jargon For Recruiters' />

              <hr />
              <div className='section'>
                <PageContent className='content' content={content} />
              </div>
              <SubscribeForm />
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

CoursesPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

export default CoursesPageTemplate
