import React from 'react'
import styled from 'styled-components'
import { Element } from 'react-scroll'

const Wap = styled.div`
  margin: 0rem;
  margin-top: 2rem;
  width: 100%;
  height: 500px;
`

const Iframe = styled.iframe`
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  min-height: 500px;
`

const IframeThing = () => {
  return (
    <Iframe
      title='waitlist'
      width='600'
      height='600'
      frameBorder='0'
      scrolling='auto'
      allowFullScreen
      src='https://cac562f9.sibforms.com/serve/MUIEAEnjCQ0tmb1Sq2CL_CuthPwg-xq61gJr3NySLOPccdyIA40EpyWAAZugxZ_NXRga-gUio710LjOtqudNYvhWxWFotMQST05oNiDuHF7dq0HSC7K8ut3lXL-0K27NTIDvjxWPgp8JxTOAN1OLktwcphGyzcS_8tjMn7uIk9oD7M0rvYC23HY2sq_tHtT4Tnag_711mbOk1vjZ'
    />
  )
}

export const SubscribeForm = () => {
  return (
    <Element name='subscribeform'>
      <Wap id='#subscribeform'>
        <IframeThing />
      </Wap>
    </Element>
  )
}
/**
 * <iframe width="540" height="305" src="
 * https://cac562f9.sibforms.com/serve/MUIEAEnjCQ0tmb1Sq2CL_CuthPwg-xq61gJr3NySLOPccdyIA40EpyWAAZugxZ_NXRga-gUio710LjOtqudNYvhWxWFotMQST05oNiDuHF7dq0HSC7K8ut3lXL-0K27NTIDvjxWPgp8JxTOAN1OLktwcphGyzcS_8tjMn7uIk9oD7M0rvYC23HY2sq_tHtT4Tnag_711mbOk1vjZ
 * " frameborder="0" scrolling="auto" allowfullscreen style="display: block;margin-left: auto;margin-right: auto;max-width: 100%;"></iframe>
 *
 */
